<template>
  <div class="row">
    <div class="col-6 d-flex">
      <span class="ml-2">از</span>
      <date-picker type="datetime" v-model="startDate" format="YYYY-MM-DD HH:mm" display-format="jYYYY-jMM-jDD HH:mm"/>
    </div>
    <div class="col-6 d-flex">
      <span class="ml-2">تا</span>
      <date-picker type="datetime" v-model="endDate" format="YYYY-MM-DD HH:mm" display-format="jYYYY-jMM-jDD HH:mm"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "DatePickerRange",
  props: {
    value: Object,
  },
  data() {
    return {
      startDate: '',
      endDate: ''
    }
  },
  watch: {
    value() {
      this.startDate = this.value.startDate
      this.endDate = this.value.endDate
    },
    startDate() {
      this.emit()
    },
    endDate() {
      this.emit()
    }
  },
  methods: {
    emit() {
      this.$emit('input', {startDate: this.startDate, endDate: this.endDate})
    }
  }
}
</script>

<style scoped>

</style>
