import {getEventLabel, getModelLabel} from '@@/core/libraries/adminFunctions'
import OrderStatus from '@@/core/components/order/OrderStatus/OrderStatus'
import ProductCommentShow from '@@/core/components/product/ProductCommentShow/ProductCommentShow'
import Chart2 from '@@/core/components/dashboard/Chart2/Chart2'
import Chart1 from '@@/core/components/dashboard/Chart1/Chart1'
import Loading from '@@/core/components/shared/Loading/Loading'
import {BFormRating} from "bootstrap-vue";
import {isPermissionError} from "@@/core/libraries/functions";

export default {
  name: "Dashboard",
  components: {
    OrderStatus,
    ProductCommentShow,
    Chart2,
    Chart1,
    Loading,
    BFormRating
  },
  mixins: [],
  data() {
    return {
      data: null,
      chartsLoaded: false,
      showingProductComment: null,
      notLoading: false,
    }
  },
  watch: {
    data() {
      this.loadCharts()
    }
  },
  created() {
    if (this.$store.getters['admin/dashboard/getData']) {
      this.data = this.$store.getters['admin/dashboard/getData'];
    } else {
      this.loadData()
    }
  },
  computed: {},
  methods: {
    getModelLabel, getEventLabel,
    async loadData() {
      try {
        this.data = await this.$store.dispatch('admin/dashboard/fetchData');
      } catch (error) {
        if (!isPermissionError(error)) {
          this.$root.notify(error)
        } else {
          this.notLoading = true
        }
      }
    },
    loadCharts() {
      setTimeout(() => {
        /*----- Employees ------*/
        var options = {
          series: [
            this.data.gender_statistics.males_count,
            this.data.gender_statistics.females_count,
            this.data.gender_statistics.unknowns_count
          ],
          chart: {
            height: 300,
            type: 'donut',
          },
          dataLabels: {
            enabled: false
          },

          legend: {
            show: false,
          },
          stroke: {
            show: true,
            width: 0
          },
          plotOptions: {
            pie: {
              donut: {
                size: '80%',
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '29px',
                    color: '#6c6f9a',
                    offsetY: -10
                  },
                  value: {
                    show: true,
                    fontSize: '26px',
                    color: undefined,
                    offsetY: 16,
                    formatter: function (val) {
                      return val
                    }
                  },
                  total: {
                    show: true,
                    showAlways: false,
                    label: 'تعداد کل',
                    fontSize: '22px',
                    fontWeight: 600,
                    color: '#373d3f',
                    // formatter: function (w) {
                    //   return w.globals.seriesTotals.reduce((a, b) => {
                    // 	return a + b
                    //   }, 0)
                    // }
                  }

                }
              }
            }
          },
          responsive: [{
            options: {
              legend: {
                show: false,
              }
            }
          }],
          labels: ["مرد", "زن", "انتخاب نشده"],
          colors: ['#3366ff', '#fe7f00', '#ffe53f'],
        };
        // eslint-disable-next-line no-undef
        var chart = new ApexCharts(document.querySelector("#employees"), options);
        chart.render();

        //________ Datepicker
        window.$(".fc-datepicker").datepicker({
          dateFormat: "dd M yy",
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
        });

        //________ Timepiocker
        window.$('#tpBasic').timepicker();

        //________ Countdonwtimer
        window.$("#clocktimer").countdowntimer({
          currentTime: true,
          size: "md",
          borderColor: "transparent",
          backgroundColor: "transparent",
          fontColor: "#313e6a",
          //timeZone : -1 //
        });
      }, 0)
    },
    showProductComment(comment) {
      this.showingProductComment = comment;
      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "comment-modal");
      })
    },
    onProductCommentStatusChanged(payload) {
      let id = payload.id;
      let status = payload.status;
      this.data.comments.product_comments = this.data.comments.product_comments.map(comment => {
        if (comment.id == id) {
          comment.status = status;
        }

        return comment;
      })
    },
    getTotalItemsInOrder(order) {
      let total = 0;
      order.items.forEach(item => total += item.quantity)
      return total;
    }
  }
}
