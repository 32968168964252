<template>
  <main  class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">گزارش محصولات</h5>
        <div style="flex: 1"></div>
        <div>
          <Dropdown v-if="$options.configProvider.get('physicalShop.active')"
                    :disabled="disabled" v-model="selectedMode" class="d-inline ml-2"
                    :options="reportTypeOptions"  />
          <button @click="print" class="btn btn-primary"><span style="transform: translateY(3px)" class="feather-printer"></span></button>
          <ExcelDownload class="mr-2" url="admin/reports/products" />
        </div>
      </div>
      <div class="row px-4 not-in-print">
        <form autocomplete="chrome-off" @submit.prevent="doFilter" class="col-12 row">
          <div class="col-6">
            <DatePickerRange v-model="filter.date" />
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button @click="removeFilter" :disabled="disabled" type="button" class="btn btn-warning ml-2">برداشتن فیلتر</button>
            <button :disabled="disabled" type="submit" class="btn btn-primary">فیلتر</button>
          </div>
        </form>
        <b-form-group class="mt-3 col-4">
          <b-form-input autocomplete="chrome-off" v-model="filter.search" placeholder="جستجو" />
        </b-form-group>
        <div v-if="sumTotalSale !== null && sumSellQuantity !== null" class="mt-3 col-12">
          <span>
            <span class="ml-1 font-weight-bold">جمع فروش:</span><span>{{ sumTotalSale | price }}</span>
          </span>
          <span class="mr-3">
            <span class="ml-1 font-weight-bold">جمع تعداد:</span><span>{{ sumSellQuantity | price }}</span>
          </span>
        </div>
      </div>
      <div id="print" class="p-4">
        <div class="d-flex mb-3 mr-2-print" v-if="appliedFilter.date.startDate || appliedFilter.date.endDate">
          <div v-if="appliedFilter.date.startDate">
            <span> از تاریخ</span><span> {{ appliedFilter.date.startDate | persianDate }} </span>
          </div>
          <div class="mr-4" v-if="appliedFilter.date.endDate">
            <span> تا تاریخ</span><span> {{ appliedFilter.date.endDate | persianDate }} </span>
          </div>
        </div>
        <div class="w-100 mt-1 mb-2 mr-2-print" v-if="filter.search">جستجوی عبارت "{{ filter.search }}"</div>
        <b-table id="main-table"  v-if="reports" bordered striped :fields="fields" :items="reports" :filter="filter.search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter-included-fields="['title', 'id']"
                  responsive="sm">
          <template v-slot:cell(total_sale)="data">
            {{ data.item.total_sale < 0 ? '-' : '' }}{{ data.item.total_sale | price  }}
          </template>
          <template v-slot:cell(title)="data">
            <a target="_blank" :href="'/admin/products/edit/' + data.item.id">{{ data.item.title }}</a>
          </template>
          <template v-slot:cell(show_varieties)="data">
            <button @click="showVarieties(data.item.id)" class="btn btn-outline-primary">تنوع ها</button>
          </template>
        </b-table>
        <ShowVarietiesModal :start-date="appliedFilter.date.startDate" :end-date="appliedFilter.date.endDate"
                            :product-id="showVarietiesProductId" :report-code="reportCode" />
        <div v-if="!reports" class="alert alert-primary">در حال بارگزاری</div>
      </div>
    </div>
  </main>
</template>

<script>
import {BTable, BFormGroup, BFormInput} from 'bootstrap-vue'
import DatePickerRange from '@@/core/components/shared/DatePickerRange/DatePickerRange'
import ShowVarietiesModal from "@@/core/components/report/ShowVarietiesModal";
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
import configProvider from "@@/core/configProvider";
import reportMixin from "@@/core/mixins/reportMixin";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload.vue";

function Filter() {
  this.search = '';
  this.date = {
    startDate: '',
    endDate: ''
  }
}

Filter.prototype.set = function(filters) {
  Object.entries(filters).forEach(([index,value]) => {
    this[index] = value
  })
}

export default {
  name: "ProductsReport",
  components: {
    ExcelDownload,
    Dropdown,
    ShowVarietiesModal,
    DatePickerRange,
    BTable, BFormGroup, BFormInput
  },
  configProvider,
  data() {
    return {
      disabled: true,
      fields: [
        {
          key: 'id',
          label: 'شناسه محصول',
          sortable: true
        },
        {
          key: 'title',
          label: 'عنوان',
          sortable: true
        },
        {
          key: 'sell_quantity',
          label: 'تعداد فروش',
          sortable: true
        },
        {
          key: 'total_sale',
          label: 'میزان فروش (تومان)',
          sortable: true
        },
        {
          key: 'show_varieties',
          label: 'تنوع ها'
        }
      ],
      page: 1,
      reports: null,
      sortBy: null,
      sortDesc: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
      showVarietiesProductId: null,
      sumTotalSale: null,
      sumSellQuantity: null,
    }
  },
  mixins: [reportMixin],
  watch: {
    selectedMode() {
      this.showVarietiesProductId = null
      this.loadData()
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    removeFilter() {
      this.filter = new Filter()
      this.loadData()
    },
    print() {
      print()
    },
    doFilter() {
      this.loadData()
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData()
    },
    async loadData() {
      try {
        this.appliedFilter.set(this.filter)
        this.disabled = true
        const response = await this.$axios.get('admin/reports/products', {
          params: {
            start_date: this.appliedFilter.date.startDate,
            end_date: this.appliedFilter.date.endDate,
            report_mini_product: this.reportCode
          }
        });
        this.reports = response.data.data.reports
        this.sumSellQuantity = response.data.data.sum_sell_quantity
        this.sumTotalSale = response.data.data.sum_total_sale
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    },
    showVarieties(productId) {
      this.$root.$emit("bv::show::modal", "show-varieties-modal");
      this.showVarietiesProductId = productId

    }
  }
}
</script>

<style>

.print-only {
  display: none;
}
@media print {
  .not-in-print {
    display: none!important;
  }

  .app-content .side-app {
    padding: 0!important;
  }

  .print-only {
    display: block!important;
  }

  body * {
    visibility: hidden;
  }
  #print, #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0!important;
  }
  #print {
    width: 80%;
  }

  #main-table th:last-child, #main-table td:last-child {
    display: none;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
