<template>
  <b-button @click="showQuantity = true" v-if="!showQuantity">پرینت </b-button>
  <form @submit.prevent="printVariety()" v-else>
    <b-form-group label="تعداد را وارد کنید">
      <b-form-input v-model="q" type="number" />
    </b-form-group>
    <b-button @click="printVariety()" variant="primary">
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />

        <g id="SVGRepo_iconCarrier">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4933 6.93502C15.8053 7.20743 15.8374 7.68122 15.565 7.99325L7.70786 16.9933C7.56543 17.1564 7.35943 17.25 7.14287 17.25C6.9263 17.25 6.72031 17.1564 6.57788 16.9933L3.43502 13.3933C3.16261 13.0812 3.19473 12.6074 3.50677 12.335C3.8188 12.0626 4.29259 12.0947 4.565 12.4068L7.14287 15.3596L14.435 7.00677C14.7074 6.69473 15.1812 6.66261 15.4933 6.93502Z"
            fill="#ffffff"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.5175 7.01946C20.8174 7.30513 20.829 7.77986 20.5433 8.07981L11.9716 17.0798C11.8201 17.2389 11.6065 17.3235 11.3872 17.3114C11.1679 17.2993 10.9649 17.1917 10.8318 17.0169L10.4035 16.4544C10.1526 16.1249 10.2163 15.6543 10.5458 15.4034C10.8289 15.1878 11.2161 15.2044 11.4787 15.4223L19.4571 7.04531C19.7428 6.74537 20.2175 6.73379 20.5175 7.01946Z"
            fill="#ffffff"
          />
        </g>
      </svg>
    </b-button>
  </form>
</template>
<script>
import { BButton, BFormInput, BFormGroup } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
  },
  props: {
    varietyId: Number,
    quantity: Number,
  },
  data() {
    return {
      showQuantity: false,
      q: this.quantity,
    };
  },
  methods: {
    printVariety() {
      this.showQuantity = false;
      window.open(
        `/admin/print-varieties?ids=${this.$route.params.id}&variety=${this.varietyId}&quantity=${this.q}`,
        "_blank"
      );
    },
  },
};
</script>
