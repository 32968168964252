export default {
  logo: require('@/assets/images/unik_logo.png'),
  print_logo: require('@/assets/images/unik_logo_b.png'),
  print_font: require('@/assets/fonts/Yekan_bakh/Yekan-Bakh-Medium.woff'),
  customer: {
    hasRole: true,
  },
  product: {
    hasVariety: true,
    hasColor: true,
    hasSizeChart: false,
    hasNewsletters: true,
    hasSets: true,
    hasFlash: true,
    hasShortDescription: false,
    showSkuAndBarcode: true,
    excelType1: true
  },
  sizeChart: {
    changeColumn: false, // کم و زیاد کردن ستون ها
    hasType: true // آیا سایز چارت تایپ دارد یا نه
  },
  hasInstagram: true,
  flash: {
    hasMobileImage: true
  },
  physicalShop: {
    active: true,
    hasSellers: true,
    printPrice: 'rial'
  }
}
