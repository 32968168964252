<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">مشاهده سفارش {{ id }}</h5>
        <div style="flex:1"></div>
        <button class="btn btn-primary ml-2" @click="print"><i class="fa fa-print"></i></button>
        <Back url="/admin/shop"/>
      </div>
      <div class="card-body">
        <div id="print" v-if="item">
          <div class="d-flex flex-column">
            <img class="logo" :src="$options.configProvider.get('print_logo')">
            <div>شماره فاکتور: <span class="font-weight-bold">{{ item.id }}</span></div>
            <div class="row-1">
              <div class="row-1-item">
                <div v-if="item.customer">مشتری: <span>{{ item.customer.full_name || item.customer.mobile }}</span></div>
                <div>تاریخ: <span>{{ item.created_at | persianDate }}</span></div>
              </div>
              <div class="row-1-item">
                <div>صندوق دار: <span>{{ item.creatorable ? item.creatorable.name : '' }}</span></div>
              </div>
            </div>
            <div v-if="sellItems.length" class="row-2">
              <div class="row-2-header black-bg">مشخصات کالا</div>
              <div class="row-2-table">
                <table class="table table-bordered mb-0">
                  <thead>
                  <tr>
                    <th>کد</th>
                    <th>عنوان</th>
                    <th>تعداد</th>
                    <th>قیمت</th>
                    <th>جمع کل</th>
                  </tr>
                  </thead>
                  <tbody>
                    <ShopShowItem v-for="miniOrderItem in sellItems" :item="miniOrderItem" :key="miniOrderItem.id" />
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="refundItems.length" class="row-2">
              <div class="row-2-header black-bg">مشخصات کالا(مرجوعی)</div>
              <div class="row-2-table">
                <table class="table table-bordered mb-0">
                  <thead>
                  <tr>
                    <th>کد</th>
                    <th>عنوان</th>
                    <th>تعداد</th>
                    <th>قیمت</th>
                    <th>جمع کل</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ShopShowItem v-for="miniOrderItem in refundItems" :item="miniOrderItem" :key="miniOrderItem.id" />
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row-3">
              <table class="table table-bordered">
                <tr>
                  <td>تعداد کالا</td>
                  <td>{{ miniOrderItemsCount }}</td>
                </tr>
                <tr>
                  <td>جمع کل ({{ getPriceName() }})</td>
                  <td>
                    {{ getPrice(item.total + item.discount_amount) | price }}<span v-if="item.total < 0">-</span></td>
                </tr>
                <tr>
                  <td>تخفیف ({{ getPriceName() }})</td>
                  <td>{{ getPrice(item.discount_amount) | price }}</td>
                </tr>
                <tr>
                  <td>جمع پرداختی ({{ getPriceName() }})</td>
                  <td class="black-bg" style="font-weight: bold">
                    {{ getPrice(item.total) | price }}<span v-if="(item.total) < 0">-</span></td>
                </tr>
              </table>
            </div>
            <div class="row-4">
              <div class="text-center w-100"><span class="ml-3">اینستاگرام</span> {{ settings.instagram }}</div>
              <div class="row-4-item">
                <div>{{ settings.shop_name }}</div>
                <div>{{ settings.shop_telephone }}</div>
                <div>{{ settings.shop_url }}</div>
              </div>
              <div>{{ settings.shop_address }}</div>
            </div>

          </div>
        </div>
        <div v-else class="d-flex w-100 justify-content-center">
          <Loading/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "@@/core/components/shared/Loading/Loading"
import configProvider from "@@/core/configProvider"
import Back from '@@/core/components/shared/Back/Back'
import { Printd } from "printd"
import styles from "@@/core/pages/physicalShop/getStyles";
import ShopShowItem from "@@/core/pages/physicalShop/components/ShopShowItem";

export default {
  name: "ShopCreate",
  configProvider,
  components: {ShopShowItem, Loading, Back},
  data() {
    return {
      item: null,
      id: this.$route.params.id,
      settings: {}
    }
  },
  computed: {
    refundItems() {
      return this.item.mini_order_items.filter(i => i.type === 'refund')
    },
    sellItems() {
      return this.item.mini_order_items.filter(i => i.type === 'sell')
    },
    miniOrderItemsCount() {
      if (!this.item) {
        return 0
      }

      return this.item.mini_order_items.reduce((a,b) => (a + b.quantity), 0)
    },
  },
  mounted() {
    this.loadItem()
  },
  methods: {
    getPriceName() {
      return configProvider.get('physicalShop.printPrice') === 'rial' ? 'ریال' : 'تومان'
    },
    getPrice(price) {
      return configProvider.get('physicalShop.printPrice') === 'rial' ? price * 10 : price
    },
    async loadItem() {
      try {
        const response = await this.$axios.get('admin/mini_orders/' + this.id, {
          params: {
            widgets: JSON.stringify(['settings'])
          }
        })
        const miniOrder = response.data.data.mini_order
        miniOrder.mini_order_items.forEach(miniOrderItem => {
          miniOrderItem.extra = JSON.parse(miniOrderItem.extra)
        })
        const settings = {}
        response.data.data.widgets.settings.forEach(s => {
          settings[s.name] = s.value
        })
        this.settings = settings
        this.item = miniOrder
        if (this.$route.query.print) {
          this.$nextTick(() => {
            this.print()
            this.$router.push(`/admin/shop/create`)
          })
        }
      } catch (e) {
        this.$root.notify(e)
      }
    },
    print() {
      const d = new Printd();
      let _styles;
      if (configProvider.get('print_font')) {
        _styles = styles + "@font-face{font-family:'yekan-bakh';src:url(" + configProvider.get('print_font') + ") format('woff');font-weight:normal;font-style:normal;}"
      } else {
        _styles = styles;
      }
      d.print(document.getElementById("print"), [_styles]);
    }
  }
}
</script>

<style scoped>
.card.little .card-header {
  font-weight: bold;
}
</style>

<style>

.logo {
  width: 100px;
  display: block;
  margin: auto;
}


.black-bg {
  background: #363636;
  color: white;
}


.row-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
}

.row-2-header {
  padding: 4px 0;
  text-align: center;
  font-weight: bold;
}

.row-4-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
