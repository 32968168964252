import "@@/core/assets/css/print.css";
import { Printd } from "printd";
import configProvider from "@@/core/configProvider";
import styles from "./getStyles";
import VueBarcode from "vue-barcode";

export default {
  name: "printVarieties",
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      varieties: [],
      finalList: {},
      productName: "",
    };
  },

  mounted() {
    this.getVarieties(this.$route.query.ids);
  },
  methods: {
    print() {
      const d = new Printd();
      let _styles;
      if (configProvider.get("print_font")) {
        _styles =
          styles +
          "@font-face{font-family:'yekan-bakh';src:url(" +
          configProvider.get("print_font") +
          ") format('woff');font-weight:normal;font-style:normal;}";
      } else {
        _styles = styles;
      }
      d.print(document.getElementById("print"), [_styles]);
    },
    async getVarieties(product_ids) {
      let params = {};
      product_ids.split(",").forEach((id, index) => {
        params[`ids[${index}]`] = id;
      });
      const response = await this.$axios(`admin/products_excels`, {
        params: params,
      });
      this.varieties.push(...response.data.data.data);
      if (this.$route.query.variety) {
        let finded = this.varieties.find((v) => {
          return v[5] == this.$route.query.variety;
        });
        this.varieties = [];
        this.varieties.push(finded);
      }
      this.productName = this.varieties[0][1];
      this.$forceUpdate();
    },
  },
};
