<template>
  <div class="card mt-3 in-product-varieties">
    <ProductChangeQuantity
      :variety="changeQuantityVariety"
      :type="changeQuantityType"
    />
    <div class="card-header border-0">
      <h5 class="card-title">تنوع ها</h5>
      <div style="flex: 1"></div>
      <div>
        <Dropdown @input="dropdownInput" :options="dropdownOptions" />
      </div>
    </div>
    <div class="card-body">
      <InputGroup
        label="رنگ"
        v-if="$options.configProvider.get('product.hasColor')"
      >
        <treeselect
          :searchable="true"
          :multiple="true"
          :options="realColors"
          noChildrenText="فرزندی وجود ندارد"
          noOptionsText="موردی وجود ندارد"
          noResultsText="موردی یافت نشد"
          placeholder="انتخاب رنگ ها"
          v-model="realValue.colors"
        >
          <label
            :style="'height: 40px'"
            slot="option-label"
            slot-scope="{ node, labelClassName }"
            :class="labelClassName"
          >
            <div
              class="color-option-img"
              :style="'background-color:' + node.raw.code"
            ></div>
            {{ node.label }}
          </label>
        </treeselect>
      </InputGroup>
      <InputGroup label="ویژگی ها">
        <treeselect
          :searchable="true"
          :multiple="true"
          :options="attributes"
          noChildrenText="فرزندی وجود ندارد"
          noOptionsText="موردی وجود ندارد"
          noResultsText="موردی یافت نشد"
          placeholder="انتخاب ویژگی ها"
          v-model="realValue.attributes"
        ></treeselect>
      </InputGroup>
      <b-form-group v-if="realValue.attributes.length">
        <div
          class="mt-3"
          v-for="attribute in selectedAttributes"
          :key="attribute.id"
        >
          <h6>
            مقادیر ویژگی
            <span class="font-weight-bold">{{ attribute.label }}</span>
          </h6>
          <treeselect
            v-if="attribute.type === 'select'"
            :searchable="true"
            :multiple="true"
            :options="attribute.values"
            noChildrenText="فرزندی وجود ندارد"
            noOptionsText="موردی وجود ندارد"
            noResultsText="موردی یافت نشد"
            placeholder="انتخاب کنید"
            v-model="realValue.selected_attribute_values[attribute.id]"
          >
            <div slot="value-label" slot-scope="{ node }">
              {{ node.raw.value }}
            </div>
            <label
              slot="option-label"
              slot-scope="{ node, labelClassName }"
              :class="labelClassName"
            >
              <div>{{ node.raw.value }}</div>
              {{ node.label }}
            </label>
          </treeselect>
          <TreeselectAddable
            v-else-if="attribute.type === 'text'"
            v-model="realValue.selected_attribute_values[attribute.id]"
            :options="getOptions(attribute)"
            :hide-options="true"
            @add-option="
              (option) => {
                addOption(attribute, option);
              }
            "
            placeholder="انتخاب کنید"
          />
        </div>
      </b-form-group>
      <template
        v-if="
          (realValue.selected_attribute_values.length &&
            selectedAttributes.length) ||
          realValue.colors.length
        "
      >
        <table class="table table-responsive-sm table-bordered table-hover">
          <thead>
            <tr>
              <th>عنوان</th>
              <th>قیمت</th>
              <th
                v-if="$options.configProvider.get('product.showSkuAndBarcode')"
              >
                بارکد - SKU
              </th>
              <th>موجودی</th>
            </tr>
          </thead>
          <draggable
            v-model="sortedVarietiesTemplate"
            tag="tbody"
            v-bind="$options.dragOptions"
          >
            <tr v-for="key in sortedVarietiesTemplate" :key="key">
              <template
                v-for="scope in [{ index: getVarietyTemplateIndexByKey(key) }]"
              >
                <VarietyRow
                  v-if="realValue.varieties[scope.index]"
                  :colors="colors"
                  :row="realValue.varieties[scope.index].ts"
                />
                <td>
                  <b-form-input
                    :style="
                      $options.configProvider.get('product.showSkuAndBarcode')
                        ? 'width: 105px'
                        : ''
                    "
                    type="text"
                    placeholder="قیمت"
                    :value="
                      realValue.varieties[scope.index].data.price | priceinput
                    "
                    @input="
                      (value) => {
                        changeVarietyPrice(scope.index, value);
                      }
                    "
                  ></b-form-input>
                </td>
                <td
                  v-if="
                    $options.configProvider.get('product.showSkuAndBarcode')
                  "
                >
                  <b-form-group>
                    <b-form-input
                      style="width: 105px"
                      v-model="realValue.varieties[scope.index].data.barcode"
                      type="text"
                      placeholder="بارکد"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      style="width: 105px"
                      v-model="realValue.varieties[scope.index].data.SKU"
                      type="text"
                      placeholder="SKU"
                    ></b-form-input>
                  </b-form-group>
                </td>
                <td>
                  <template
                    v-if="
                      mode !== 'edit' ||
                      !realValue.varieties[scope.index].data.id
                    "
                  >
                    <b-form-input
                      class="d-inline"
                      type="text"
                      placeholder="موجودی"
                      v-model="realValue.varieties[scope.index].data.quantity"
                    />
                  </template>
                  <template v-else>
                    <button
                      @click="
                        changeQuantity(
                          realValue.varieties[scope.index].data,
                          'increment'
                        )
                      "
                      class="btn btn-success"
                    >
                      +
                    </button>
                    <b-form-input
                      disabled
                      class="d-inline mx-2"
                      :style="
                        $options.configProvider.get('product.showSkuAndBarcode')
                          ? 'width: 50px'
                          : 'width: 110px'
                      "
                      type="text"
                      placeholder="موجودی"
                      v-model="realValue.varieties[scope.index].data.quantity"
                    />
                    <button
                      @click="
                        changeQuantity(
                          realValue.varieties[scope.index].data,
                          'decrement'
                        )
                      "
                      class="btn btn-danger"
                    >
                      ‒
                    </button>
                  </template>
                </td>
                <td class="modal-images">
                  <a
                    v-b-modal="'modal-variety-information-' + scope.index"
                    class="action-btns1 pt-1 px-2"
                    ><i class="fa fa-edit" data-toggle="tooltip"></i
                  ></a>
                  <a
                    v-b-modal="'modal-variety-image-' + scope.index"
                    class="action-btns1 pt-1 px-2 position-relative"
                    ><i
                      class="fa fa-file-image-o"
                      data-toggle="tooltip"
                      title="عکس ها"
                    ></i>
                    <span
                      class="image-numbers badge badge-secondary"
                      v-if="realValue.varieties[scope.index].data.images.length"
                      >{{
                        realValue.varieties[scope.index].data.images.length
                      }}</span
                    >
                  </a>
                  <printVarietyBtn
                    v-if="$route.name == 'productEdit'"
                    :quantity="realValue.varieties[scope.index].data.quantity"
                    :varietyId="realValue.varieties[scope.index].data.id"
                  />
                  <b-modal
                    hide-header
                    modal-class="modal-image"
                    hide-footer
                    :id="'modal-variety-image-' + scope.index"
                    title="عکس ها"
                  >
                    <ProductImages
                      hide-shadow
                      v-model="realValue.varieties[scope.index].data.images"
                    />
                  </b-modal>
                  <b-modal
                    modal-class="modal-image"
                    hide-footer
                    :id="'modal-variety-information-' + scope.index"
                    title="اطلاعات تنوع"
                  >
                    <VarietyInformation
                      :gifts="gifts"
                      v-model="realValue.varieties[scope.index].data"
                    />
                    <button
                      class="btn btn-warning"
                      @click="
                        $root.$emit(
                          'bv::hide::modal',
                          'modal-variety-information-' + scope.index
                        )
                      "
                    >
                      بستن
                    </button>
                  </b-modal>
                </td>
              </template>
            </tr>
          </draggable>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import ProductVarietiesImpl from "@@/core/components/product/ProductVarieties/ProductVarietiesImpl";
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
export default {
  components: { Dropdown },
  extends: ProductVarietiesImpl,
};
</script>

<style
  scoped
  src="@@/core/components/product/ProductVarieties/ProductVarietiesStyle.css"
></style>
<style
  src="@@/core/components/product/ProductVarieties/ProductVarietiesStylePublic.css"
></style>
