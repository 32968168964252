export default `
#print {
font-size:12px;

}

  * {
    visibility: visible !important;
  }
 html, body {
    margin: 0;
    padding: 0;
    height: auto; /* Ensure height is dynamic for printing */
  }
}



    p:empty, div:empty {
  display: none;
}
body {
  -webkit-print-color-adjust: exact;
}

#print ul li {
    display: flex;
    flex-direction: column;
    align-items:center;
  overflow: hidden;
  height:20mm;
    border: unset !important;
  position: relative !important;
}
 * {
  font-size: 12px ;
}

    #print  {
    page-break-before: auto;
    page-break-inside: avoid;
    page-break-after: auto;
  }

#print ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  row-gap: calc(100vh - 20mm);
  margin-bottom: 0;

}

    #print #printBtn{display:none!important;}

#print ul li i {
  color: black;
  margin-bottom: -3px;
  display: block;
  white-space: nowrap;
  height: 16px;
  width: 100%;
  text-overflow: ellipsis;
  text-align: center;
  position: absolute;
  font-size:7px !important;
  top:0;
  z-index:100;
}
 
    /*! CSS Used from: Embedded */
#print *,*::before,*::after{box-sizing:border-box;}
#print section{display:block;}
#print p{margin-top:0;margin-bottom:1rem;}
#print th{text-align:inherit;text-align:-webkit-match-parent;}
#print button{border-radius:0;}
#print button:focus:not(:focus-visible){outline:0;}
#print button{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
#print button{overflow:visible;}
#print button{text-transform:none;}
#print button{-webkit-appearance:button;}
#print button::-moz-focus-inner{padding:0;border-style:none;}
#print .table{width:100%;margin-bottom:1rem;color:#212529;}
#print .table th,.table td{padding:1px 2px;vertical-align:top;}
#print .table thead th{vertical-align:bottom;border-bottom:2px solid #040404;}
#print .table-bordered{border:1px solid #040404;}
#print .table-bordered th,.table-bordered td{border:1px solid #040404;}
#print .table-bordered thead th{border-bottom-width:2px;}
#print .table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;}
#print .table-responsive > .table-bordered{border:0;}
#print .btn{display:inline-block;font-weight:400;color:#212529;text-align:center;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;
padding:1px 2px;
  font-size:14px;line-height:1.5;border-radius:0.25rem;transition:color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;}
#print.d-flex{display:flex!important;}
#print .flex-column{flex-direction:column!important;}
#print .justify-content-end{justify-content:flex-end!important;}
#print .align-items-center{align-items:center!important;}
#print .mx-0{margin-right:0!important;}
#print .mb-0{margin-bottom:0!important;}
#print .mx-0{margin-left:0!important;}
#print .mr-1{margin-right:0.25rem!important;}
#print .mt-2{margin-top:0.5rem!important;}
#print .mb-4{margin-bottom:1.5rem!important;}
#print .p-1{padding:0.25rem!important;}
#print .pr-1{padding-right:0.25rem!important;}
#print .pt-2,.py-2{padding-top:0.5rem!important;}
#print .pr-2,.px-2{padding-right:0.5rem!important;}
#print .py-2{padding-bottom:0.5rem!important;}
#print .pl-2,.px-2{padding-left:0.5rem!important;}
#print .py-3{padding-top:1rem!important;}
#print .px-3{padding-right:1rem!important;}
#print .py-3{padding-bottom:1rem!important;}
#print .px-3{padding-left:1rem!important;}
#print .px-4{padding-right:1.5rem!important;}
#print .px-4{padding-left:1.5rem!important;}
@media (min-width: 992px){
#print .pb-lg-1{padding-bottom:0.25rem!important;}
}
#print .text-right{text-align:right!important;}
#print .text-center{text-align:center!important;}
#print .font-weight-bold{font-weight:700!important;}
#print .text-white{color:#fff!important;}
#print .table-responsive{margin-bottom:1rem;}
#print .table-responsive > .table{margin-bottom:0;}
#print .btn{font-size:14px;}
#print .table-bordered,.table-bordered th,.table-bordered td{text-align:center;}
/*! CSS Used from: Embedded */
#print *,::after,::before{box-sizing:border-box;}
#print section{display:block;}
#print p{margin-top:0;margin-bottom:1rem;}
#print th{text-align:inherit;text-align:-webkit-match-parent;}
#print button{border-radius:0;}
#print button:focus:not(:focus-visible){outline:0;}
#print button{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
#print button{overflow:visible;}
#print button{text-transform:none;}
#print button{-webkit-appearance:button;}
#print button::-moz-focus-inner{padding:0;border-style:none;}
#print .table{width:100%;margin-bottom:1rem;color:#212529;}
#print .table td,.table th{padding:1px 2px;vertical-align:top;}
#print .table thead th{vertical-align:bottom;border-bottom:2px solid #040404;}
#print .table-bordered{border:1px solid #040404;}
#print .table-bordered td,.table-bordered th{border:1px solid #040404;}
#print .table-bordered thead th{border-bottom-width:2px;}
#print .table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;}
#print .table-responsive>.table-bordered{border:0;}
#print .btn{display:inline-block;font-weight:400;color:#212529;text-align:center;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.375rem .75rem;
font-size:14px;line-height:1.5;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
#print .btn:hover{color:#212529;text-decoration:none;}
#print .btn:focus{outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
#print .btn:disabled{opacity:.65;}
#print .d-flex{display:flex!important;}
#print .flex-column{flex-direction:column!important;}
#print .justify-content-end{justify-content:flex-end!important;}
#print .align-items-center{align-items:center!important;}
#print .mx-0{margin-right:0!important;}
#print .mb-0{margin-bottom:0!important;}
#print .mx-0{margin-left:0!important;}
#print .mr-1{margin-right:.25rem!important;}
#print .mt-2{margin-top:.5rem!important;}
#print .mb-4{margin-bottom:1.5rem!important;}
#print .p-1{padding:.25rem!important;}
#print .pr-1{padding-right:.25rem!important;}
#print .pt-2,.py-2{padding-top:.5rem!important;}
#print .pr-2,.px-2{padding-right:.5rem!important;}
#print .py-2{padding-bottom:.5rem!important;}
#print .pl-2,.px-2{padding-left:.5rem!important;}
#print .py-3{padding-top:1rem!important;}
#print .px-3{padding-right:1rem!important;}
#print .py-3{padding-bottom:1rem!important;}
#print .px-3{padding-left:1rem!important;}
#print .px-4{padding-right:1.5rem!important;}
#print .px-4{padding-left:1.5rem!important;}
@media (min-width:992px){
#print .pb-lg-1{padding-bottom:.25rem!important;}
}
#print .text-right{text-align:right!important;}
#print .text-center{text-align:center!important;}
#print .font-weight-bold{font-weight:700!important;}
#print .text-white{color:#fff!important;}
#print *{padding:0px;margin:0PX;border:none;font-family:'yekan-bakh', tahoma;direction:rtl;}

#print .print-order-item-title{background:#ddd;vertical-align:middle!important;position:relative !important;width:100px;
height:60px;}
#print .print-order-item-title > span{background:#ddd;width:100%;height:100%;line-height:55px;display:inline-block;font-weight:bold;padding:5px;}
#print .print-order-item-call{border-right:1px solid #040403;vertical-align:middle!important;
width:160px;
}
#print .print-order .print-customer-order-table thead th{border:1px solid #040403;text-align:center;}
#print .print-order .print-customer-order-table .table-bordered td{border:1px solid #040403!important;}
#print .print-order .print-customer-order-table tbody td{font-size:14px!important;vertical-align:middle;}
#print .print-order .print-order-shop td{padding:0!important;}
#print .print-order .print-order-total-table td{border:1px solid #040403;padding:1px 3px!important;}
#print .print-order .print-customer-table td{border:1px solid #040403!important;}
#print .print-order .btn-print{background-color:#040403;}
@media screen and (max-width: 1020px){
#print .print-order .print-order-item-info span{font-size:14px!important;}
}
@media screen and (max-width: 900px){
#print .print-order .print-order-item-info .print-order-item-address{display:block!important;}
}

    #print .print-customer-order-table th:first-child {
      padding-right:11px!important;

    }
    .print-customer-order-table th {
      background: #dddddd;
    }
    .print-customer-order-table tr td:first-child {
      background: #dddddd;
    }
    #print .print-order-total-table td:nth-child(2) {
      text-align: center!important;
    }
    #print .print-order .print-order-shop .print-order-item-info{padding-bottom:0!important;padding-right:8px!important;
     
    }

#print tr {
  page-break-inside: avoid!important;
}

#print td {
  page-break-inside: avoid;
  page-break-after: avoid;
}

#print thead img { page-break-inside: auto !important; }

#print button {
  position: fixed;
  left: 5%;
  bottom: 5%;
  padding: 8px;
  background-color: #0087ff;
  color: white;
  border: unset;
  border-radius: 4px;
}

#print .varietyRow {
  display: flex;
  align-items: center;
  max-height: 20mm;
  overflow: hidden;
  position: relative !important;
}
#print .varietyRow li {
  list-style: none;
}
.variety {
  flex-direction: row !important;
  justify-content: center;
}
span.varName {
  font-size: 8px !important;
  bottom:8px !important;
  position: relative !important;

}
#print ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0;
}

#print ul li * {
  font-size: 12px;
}

#print ul li i {
  color: black;
  margin-bottom: -3px;
  display: block;
  white-space: nowrap;
  height: 16px;
  width: 100%;
  text-overflow: ellipsis;
  text-align: center;
}


input.varietyPrice {
  position: absolute;
  z-index: 10;
  background: transparent;
  text-align: center;
}
.varietyBarcode {
  position: relative !important;
  top: 12px !important;
}
input.varietyPrice {
  font-size: 10px !important;
  top: 3px;
}
span.shopName {
  font-size: 9px !important;
  transform: rotate(90deg) translateY(18px);
  white-space: nowrap;
  display: block;
}
.varietyContainer {
  display: flex;
  align-items: center;
  max-width: 30mm;
  overflow: hidden;
  justify-content: flex-end;
}
.barcodeContainer {
  position: relative !important;
  top:5px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
    padding: 0.5mm 10px !important;
}
input.varietyPrice {
  font-size: 10px !important;
  top:  10px !important;
  left: 50%;
  transform: translateX(-50%);
}
span.varietyId {
  position: absolute;
  top: 35px !important;
  background: white;
  z-index: 10000;
  font-size: 10px !important;
  width: 50%;
  text-align: center;
}
.priceName {
  position: relative !important;
  z-index: 12;
  bottom:5px !important;
}
span.shopName {
  font-size:9px !important;
  transform: rotate(90deg) translateY(14px) !important;
  white-space: nowrap !important;
  display: block !important;
  position: absolute !important;
  left: 0px !important;
  top: 31px !important;
}


`;
