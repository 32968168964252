export default {
    name: "Ckeditor",
    props: {
      value: String,
      id: String
    },
    data() {
      return {
        realValue: this.value,
        oldValue: this.value
      }
    },
    watch: {
      value: function (newVal) {
        this.realValue = newVal
        if (this.realValue !== this.oldValue) {
          this.oldValue = this.realValue
          window.CKEDITOR.instances[this.id].setData(newVal)
        }
      },
      realValue: function (newVal) {
        this.$emit('input', newVal)
      }
    },
    mounted() {
      window.removeEventListener('click', this.outsideClicked, true)
      window.addEventListener("click", this.outsideClicked, true);
      if (window.CKEDITOR && window.CKEDITOR.instances[this.id]) {
        try {
          window.CKEDITOR.instances[this.id].destroy();
        } catch (e){let ee;}
      }
      this.$root.loadCkeditor(this.id);
    },
    methods: {
        outsideClicked(e) {
          if (!this.$root.hasId(e.target, this.id)&&window.CKEDITOR) {
            this.oldValue = window.CKEDITOR.instances[this.id].getData()
            this.$emit('input', this.oldValue)
          }
        }
    },
    beforeDestroy(){
      window.removeEventListener('click', this.outsideClicked, true)
    }
  }
