<template>
  <div>
    <label for="">وضعیت سفارش</label>
    <multiselect
        :multiple="true"
        placeholder="وضعیت"
        v-model="statuses"
        open-direction="bottom"
        :options="$options.statusFilterOptions"
        label="title"
        track-by="value"
        :searchable="false"
        :close-on-select="false"
        :show-labels="false"
        :disabled="disabled"
    >
      <template slot="noOptions">
        موردی یافت نشد
      </template>
      <template slot="noResult">
        موردی یافت نشد
      </template>
    </multiselect>
  </div>
</template>

<script>
import {getStatusLabel} from "@@/core/libraries/adminFunctions";

export default {
  name: "OrderStatusMultiselect",
  data() {
    return {
      statuses: this.value
    }
  },
  watch: {
    statuses() {
      this.$emit('input', this.statuses)
    }
  },
  props: {
    disabled: Boolean,
    value: {
      type: Array,
      default: []
    },
  },
  statusFilterOptions: [
    {
      value: "delivered",
      title: getStatusLabel("delivered"),
    },
    {
      value: "in_progress",
      title: getStatusLabel("in_progress"),
    },
    {
      value: "new",
      title: getStatusLabel("new"),
    },
    {
      value: "reserved",
      title: getStatusLabel("reserved"),
    },
    {
      value: "wait_for_payment",
      title: getStatusLabel("wait_for_payment"),
    },
    {
      value: "canceled",
      title: getStatusLabel("canceled"),
    },
    {
      value: "failed",
      title: getStatusLabel("failed"),
    },
  ],

}
</script>

<style scoped>

</style>
