<template>
  <main  class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">گزارش تنوع ها</h5>
        <div style="flex: 1"></div>
        <button @click="print" class="btn btn-primary"><span style="transform: translateY(3px)" class="feather-printer"></span></button>
        <ExcelDownload class="mr-2" url="admin/reports/varieties-list" />
      </div>
      <div class="row px-4 not-in-print">
        <form autocomplete="chrome-off" @submit.prevent="doFilter" class="col-12 row">
          <div class="col-6">
            <DatePickerRange v-model="filter.date" />
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button :disabled="disabled" type="submit" class="btn btn-primary">فیلتر</button>
          </div>
        </form>
        <b-form-group class="mt-3 col-4">
          <b-form-input autocomplete="chrome-off" v-model="filter.search" placeholder="جستجو" />
        </b-form-group>
      </div>
      <div id="print" class="p-4">
        <div class="d-flex mb-3 mr-2-print" v-if="appliedFilter.date.startDate || appliedFilter.date.endDate">
          <div v-if="appliedFilter.date.startDate">
            <span> از تاریخ</span><span> {{ appliedFilter.date.startDate | persianDate }} </span>
          </div>
          <div class="mr-4" v-if="appliedFilter.date.endDate">
            <span> تا تاریخ</span><span> {{ appliedFilter.date.endDate | persianDate }} </span>
          </div>
        </div>
        <div class="w-100 mt-1 mb-2 mr-2-print" v-if="filter.search">جستجوی عبارت "{{ filter.search }}"</div>
        <b-table :total-rows="totalRows" :per-page="perPage" :current-page="currentPage"
                 id="main-table"  v-if="reports" bordered striped :fields="fields" :items="reports" :filter="filter.search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter-included-fields="['title', 'id']"
                  responsive="sm">
          <template v-slot:cell(index)="data">
            {{ data.index + 1 + ((page - 1) * perPage) }}
          </template>
          <template v-slot:cell(total_sale)="data">
            {{ data.item.total_sale | price  }}
          </template>
          <template v-slot:cell(title)="data">
            <a target="_blank" :href="'/admin/products/edit/' + data.item.product.id">{{ data.item.title }}</a>
          </template>
        </b-table>
        <b-pagination
            class="not-in-print"
            v-if="reports"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
        ></b-pagination>
        <div v-if="!reports" class="alert alert-primary">در حال بارگزاری</div>
      </div>
      <div class="d-flex flex-column w-100">
        <!--        <div>-->
        <!--          <div class="d-flex">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ field.label }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="d-flex w-100" v-for="item in reports">-->
        <!--            <div class="p-5" style="border: 1px solid" v-for="field in fields">-->
        <!--              {{ item[field.key] }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

    </div>
  </main>
</template>

<script>
import {BTable, BFormGroup, BFormInput, BPagination} from 'bootstrap-vue'
import DatePickerRange from '@@/core/components/shared/DatePickerRange/DatePickerRange'
import {getVarietyTitle} from "@@/core/libraries/functions";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";

function Filter() {
  this.search = '';
  this.date = {
    startDate: '',
    endDate: ''
  }
}

Filter.prototype.set = function(filters) {
  Object.entries(filters).forEach(([index,value]) => {
    this[index] = value
  })
}

export default {
  name: "ProductsReport",
  components: {
    ExcelDownload,
    DatePickerRange,
    BTable, BFormGroup, BFormInput, BPagination
  },
  data() {
    return {
      disabled: true,
      fields: [
        {
          key: 'index',
          label: 'ردیف'
        },
        {
          key: 'product_id',
          label: 'شناسه محصول',
          sortable: true
        },
        {
          key: 'title',
          label: 'عنوان',
          sortable: true
        },
        {
          key: 'sell_quantity',
          label: 'تعداد فروش',
          sortable: true
        },
        {
          key: 'total_sale',
          label: 'میزان فروش (تومان)',
          sortable: true
        }
      ],
      page: 1,
      perPage: 200,
      reports: null,
      sortBy: null,
      sortDesc: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
      currentPage: 1,
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    totalRows() {
      return this.reports ? this.reports.length : 0;
    }
  },
  methods: {
    print() {
      print()
    },
    doFilter() {
      this.loadData()
    },
    async loadData() {
      try {
        this.appliedFilter.set(this.filter)
        this.disabled = true
        const response = await this.$axios.get('admin/reports/varieties-list', {
          params: {
            start_date: this.appliedFilter.date.startDate,
            end_date: this.appliedFilter.date.endDate
          }
        });
        this.reports = response.data.data.reports.map(v => {
          v.title = v.product.title + ' ' +  (v.name ? v.name + ' ' : '') + getVarietyTitle(v);
          return v
        })
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    }
  }
}
</script>

<style>

.print-only {
  display: none;
}
@media print {
  .not-in-print {
    display: none!important;
  }

  .app-content .side-app {
    padding: 0!important;
  }

  .print-only {
    display: block!important;
  }

  body * {
    visibility: hidden;
  }
  #print, #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    padding: 0!important;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }

  .mr-2-print {
    margin-right: 20px;
  }
}
</style>
