<template>
  <section id="print">
    <div class="rowVar">
      <ul>
        <template v-for="(variety, index) in varieties">
          <template v-if="$route.query.quantity">
            <li v-for="q in +$route.query.quantity" class="variety varietyRow">
              <div class="varietyContainer">
                <div :key="q * index + index" class="barcodeContainer">
                  <input
                    type="text"
                    v-model="productName"
                    class="varietyPrice"
                  />

                  <barcode
                    class="varietyBarcode"
                    fontSize="8"
                    width="1"
                    marginBottom="0"
                    :displayValue="false"
                    height="20"
                    :value="variety[0]"
                  >
                  </barcode>

                  <span class="varietyId">
                    {{ variety[0] }}
                  </span>
                  <span class="priceName">
                    {{ variety[2] }}
                  </span>
                  <span class="varName">
                    {{ variety[4] }}
                  </span>
                  <span class="shopName">فروشگاه unik</span>
                </div>
              </div>
            </li>
          </template>
          <template v-else>
            <li v-for="q in variety[3]" class="variety varietyRow">
              <div class="varietyContainer">
                <div :key="q * index + index" class="barcodeContainer">
                  <input
                    type="text"
                    v-model="productName"
                    class="varietyPrice"
                  />

                  <barcode
                    class="varietyBarcode"
                    fontSize="8"
                    width="1"
                    marginBottom="0"
                    :displayValue="false"
                    height="20"
                    :value="variety[0]"
                  >
                  </barcode>
                  <span class="varietyId">
                    {{ variety[0] }}
                  </span>
                  <span class="priceName">
                    {{ variety[2] }}
                  </span>
                  <span class="varName">
                    {{ variety[4] }}
                  </span>
                  <span class="shopName">فروشگاه unik</span>
                </div>
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>
    <button id="printBtn" @click="print">پرینت</button>
  </section>
</template>

<script>
import printVarietiesImpl from "@@/core/pages/printVarieties/printVarietiesImpl";
export default {
  extends: printVarietiesImpl,
};
</script>

<style src="@@/core/pages/printVarieties/printVarietiesStyle.css"></style>
